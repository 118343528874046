import * as React from 'react'

import { InputProps as FormLibInputProps } from '@dropscan/forms'

import { Input, Props as InputProps } from '../forms/Input'
import splitValidProps from '../styleFunctions/splitProps'
import { default as Label, Props as LabelProps } from './Label'

type LabelHTMLAttributes = Omit<
  React.HTMLAttributes<HTMLLabelElement>,
  'onBlur' | 'onClick' | 'value' | 'onChange'
>

type InputHTMLAttributes = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'maxLength' | 'required'
>

interface Props
  extends Partial<FormLibInputProps<string>>,
    InputProps,
    LabelProps,
    LabelHTMLAttributes,
    InputHTMLAttributes {
  type?: 'text' | 'email' | 'number'
}

/**
 * In addition to the usual `<input>` props, our `TextInput` accepts:
 *
 *   - `spacing` preset names: 'normal', 'big', 'compact'.
 *   - `validationState`: 'none', 'validated', 'error'.
 *   - `labelText`: shown in a label above the input.
 *   - `error`: message placed below the input (ignored unless `validationState == 'error'`).
 *
 */
export default React.forwardRef(function TextInput(
  {
    labelText,
    spacing,
    validationState,
    type,
    maxLength,
    styles,
    error,
    onChange,
    onChangeValue, // don't forward this to the underlying <input>
    ...rest
  }: Props & React.InputHTMLAttributes<HTMLInputElement>,
  ref: React.Ref<HTMLInputElement>,
) {
  const { validProps, otherProps } = splitValidProps(rest)
  const stylingProps = { spacing, validationState, styles }
  const handleChange =
    (onChange || onChangeValue) &&
    ((event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event)
      }
      if (!event.defaultPrevented && onChangeValue) {
        onChangeValue(event.currentTarget.value)
      }
    })
  return (
    <Label {...stylingProps} {...otherProps}>
      {labelText && (
        <span>
          {labelText} {rest.required === false ? <small>• optional</small> : null}
        </span>
      )}
      <Input
        ref={ref}
        type={type || 'text'}
        maxLength={maxLength}
        {...validProps}
        {...stylingProps}
        onChange={handleChange}
      />
      {validationState === 'error' && error}
    </Label>
  )
})
