import { useMemo, useRef } from 'react'

import * as Lens from './lens'

/** A RefLens is an OO interface for compose lenses over a mutable React ref. */
export interface RefLens<T> {
  get: () => T
  set: (next: T) => void
  compose: <U>(l: Lens.Lens<T, U>) => RefLens<U>
  withDefault: (fallback: T) => this
  /** Return a lens that will call `fn` whenever a new value is set. */
  observe: (fn: (next: T) => void) => this
  memo: () => this
}

export function useRefLens<T>(initialValue: T): RefLens<T> {
  const ref = useRef(initialValue)
  return useMemo(
    () =>
      makeRefLens(
        Lens.make(
          () => ref.current,
          (next, _) => {
            ref.current = next
            return undefined
          },
        ),
      ),
    [],
  )
}

function makeRefLens<T>(lens: Lens.Lens<void, T>): RefLens<T> {
  return {
    get: () => Lens.get(lens, void 0),
    set: (next: T) => Lens.set(lens, next, void 0),
    compose: <U>(l2: Lens.Lens<T, U>) => makeRefLens(Lens.compose(lens, l2)),
    withDefault: (fallback: T) => makeRefLens(Lens.withDefault(fallback, lens)),
    memo: () => makeRefLens(Lens.memo1(lens)),
    observe: observer => {
      return makeRefLens(
        Lens.make(
          () => Lens.get(lens, void 0),
          next => {
            Lens.set(lens, next, void 0)
            observer(next)
          },
        ),
      )
    },
  }
}
