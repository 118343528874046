import typedKeys from './typedKeys'

/** Return false if any defined primitive values are found in `x`.

Note that this definition allows for an object or array to contain further
empty objects or arrays. So the following would all return `true`:

```ts
empty(null)
empty(undefined)
empty([])
empty({})
empty([null, {}, undefined]) // still true
empty({foo: null, bar: [undefined, undefined]}) // also true
```
*/
export default function empty(x: unknown): boolean {
  if (x == null) {
    return true
  }
  if (typeof x === 'object') {
    if (x == null) {
      return true
    }
    if (Array.isArray(x)) {
      return x.every(empty)
    }
    return typedKeys(x).every(k => empty(x[k]))
  }
  return false
}
