import { isValidElement, ReactElement } from 'react'

/* eslint-disable @typescript-eslint/no-explicit-any */
import typedKeys from '@dropscan/util/typedKeys'

export type Prim = number | string | boolean | symbol | bigint | ReactElement

export type MapPrimitives<T, U> = {
  [K in keyof T]?: NonNullable<T[K]> extends Prim
    ? U
    : T[K] extends (infer E)[]
    ? MapPrimitives<E, U>[]
    : NonNullable<T[K]> extends object
    ? MapPrimitives<NonNullable<T[K]>, U>
    : never
}

const isObjectOrNull = (x: unknown): x is object | null | undefined =>
  (typeof x === 'object' && !isValidElement(x)) || typeof x === 'undefined'

const isPrimOrNull = (x: unknown): x is Prim | null | undefined =>
  x == null || typeof x !== 'object' || isValidElement(x)

const isNull = (x: unknown): x is null | undefined => x == null

export function zipTraverse<X extends object, L, R, T>(
  f: (lft?: L, rgt?: R) => T,
  lft: MapPrimitives<X, L>,
  rgt: MapPrimitives<X, R>,
  dbg = '',
): MapPrimitives<X, T> {
  const result = {} as MapPrimitives<X, T>
  const keys = new Set(typedKeys(lft))
  typedKeys(rgt).forEach(key => {
    keys.add(key)
  })
  keys.forEach(key => {
    const lval = lft[key] as L
    const rval = rgt[key] as R

    if (lval == null && rval == null) {
      return
    } else if (Array.isArray(lval) || (lval == null && Array.isArray(rval))) {
      const larray = isNull(lval) ? [] : (lval as any[])
      const rarray = isNull(rval) ? [] : (rval as any[])
      const resultArray = []
      for (let i = 0, len = Math.max(larray.length, rarray.length); i < len; i++) {
        resultArray[i] = f(larray[i], rarray[i])
      }
      result[key] = resultArray as any
    } else if (isObjectOrNull(lval) && isObjectOrNull(rval)) {
      const lobject: object = isNull(lval) ? {} : lval
      const robject: object = isNull(rval) ? {} : rval
      result[key] = zipTraverse(f, lobject, robject, dbg + '/' + key) as any
    } else if (isPrimOrNull(lval) && isPrimOrNull(rval)) {
      result[key] = f(lval, rval) as any
    }
  })
  return result
}

/* Create a map-like object that will lazily initialize field values using the given callback */
export function memoMap<K, T>(
  fn: (key: K) => T,
): { get: typeof fn; has: (key: K) => boolean; values: () => IterableIterator<T> } {
  const map = new Map<K, T>()
  const get = (key => {
    const memo = map.get(key)
    if (memo) {
      return memo
    } else {
      const value = fn(key)
      map.set(key, value)
      return value
    }
  }) as typeof fn
  return {
    get,
    has: key => map.has(key),
    values: () => map.values(),
  }
}
