import isPropValid from '@emotion/is-prop-valid'

/**
 *
 * @param props
 * @param omit
 */
export default function splitValidProps<P>(
  props: P,
  omit?: (prop: string) => boolean,
): { validProps: Partial<P>; otherProps: Partial<P> } {
  const result = { validProps: {} as Partial<P>, otherProps: {} as Partial<P> }
  for (const key in props) {
    if (!isPropValid(key) || (omit && omit(key))) {
      result.otherProps[key] = props[key]
    } else {
      result.validProps[key] = props[key]
    }
  }
  return result
}
