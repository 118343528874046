export default function serializeCalls<Args extends unknown[], Result>(
  initialDelay: number,
  debounce: number,
  fn: (...args: Args) => Promise<Result>,
): (...args: Args) => Promise<Result> {
  let running: null | Promise<Result> = null
  let timeout: ReturnType<typeof setTimeout> | null = null
  let repeat = false
  let lastArgs: null | Args = null
  const waiters: ((r: Result) => void)[] = []
  const go = () => {
    if (running) {
      throw new Error('serialize tried to call fn concurrently')
    }
    timeout = null
    running = fn(...lastArgs!)
    running.then(
      result => {
        running = null
        if (repeat) {
          repeat = false
          timeout = setTimeout(go, debounce)
        } else {
          waiters.splice(0, waiters.length).forEach(f => f(result))
        }
      },
      error => {
        console.error('serialized callback error')
        console.error(error)
        waiters.splice(0, waiters.length)
      },
    )
  }
  return (...args: Args) => {
    lastArgs = args
    const p = new Promise<Result>(r => waiters.push(r))
    if (running) {
      repeat = true
      return p
    }
    const nextDelay = timeout ? debounce : initialDelay
    if (timeout) {
      clearTimeout(timeout)
    }
    if (nextDelay > 0) {
      timeout = setTimeout(go, nextDelay)
    } else {
      go()
    }
    return p
  }
}
