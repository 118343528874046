/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useRef } from 'react'

import * as Lens from '@dropscan/lens'
import { RefLens, useRefLens } from '@dropscan/lens/react'
import empty from '@dropscan/util/empty'
import flatIterator from '@dropscan/util/flatIterator'
import serializeCalls from '@dropscan/util/serializeCalls'

import { MapPrimitives, memoMap, Prim, zipTraverse } from './utils'
import typedKeys from '@dropscan/util/typedKeys'

export type Validator<Data, ValidationOpts = {}> = (
  data: Data,
  opts?: ValidationOpts,
) => Errors<Data> | Promise<Errors<Data>>

interface FormConfig<Data, ValidationOpts = {}> {
  /** The initial data to start out the form. This data should be structurally valid (have the correct type) */
  initialData: Data
  /** Initial errors that should be displayed on first render of the form */
  initialErrors?: Errors<Data>
  /** The render callback that will receive stateful form props and render the children */
  validate?: Validator<Data, ValidationOpts>
  onSubmit: (data: Data) => Promise<void>
  // /** subscribe to changes in the form data */
  // onChange?: (data: Data) => void
  disabled?: boolean
  /** If true, the form will be reset with `props.initialData` after a successful submission */
  resetAfterSubmit?: boolean
}

export type ValidationState = 'validated' | 'error' | 'none'

export interface Form<Data, ValidationOpts> extends Fieldset<Data> {
  submit(): void
  validate(options?: ValidationOpts): Promise<Errors<Data>>
  meta: {
    touched: boolean
    validationState: ValidationState
    submitting: boolean
    disabled: boolean
  }
}

export interface Fieldset<Data> {
  values: () => Data
  errors: () => Errors<Data>
  reset: (v: Partial<Data>, clearTouched?: boolean) => void
  field: <K extends keyof Data>(key: K) => Field<Data[K]>
  fieldset: <K extends keyof Data>(key: K) => Data[K] extends Prim ? never : Fieldset<Data[K]>
  list: <K extends keyof Data>(key: K) => _FieldsetListFor<Data[K]>
  touchAll: () => void
}

type _FieldsetListFor<T> = T extends Array<infer Element> ? FieldsetList<Element> : never

export interface FieldsetList<T> {
  filter: (pred: (item: T, index: number) => boolean) => void
  push: (newItem: T) => void
  clear: () => void
  remove: (index: number) => void
  map: <U>(fn: (fieldset: Fieldset<T>, index: number) => U) => U[]
  values: () => T[]
  size: () => number
}

export interface Field<T> {
  comp: <U>(lens: Lens.Lens<T, U>) => Field<U>
  compose: <U>(lens: Lens.Lens<T, U>) => InputProps<U>
  get: () => T
  set: (next: T) => void
  touch: () => void
  props: InputProps<T>
}

export interface InputProps<T> {
  name: string // always provided
  value: T
  onChangeValue: (newValue: T) => void
  disabled?: boolean
  onBlur: () => void
  validationState?: ValidationState
  error?: string
}

type Touched<T> = MapPrimitives<T, boolean>
type ValidationStates<T> = MapPrimitives<T, ValidationState>

export type Errors<T> = MapPrimitives<T, string | ReactElement | undefined>

const increment = (x: number) => x + 1

export function useForm<Data extends object, ValidationOpts = {}>(
  config: FormConfig<Data, ValidationOpts>,
): Form<Data, ValidationOpts> {
  const [, rerender] = React.useReducer(increment, 0)
  const values = useRefLens(config.initialData)
  const errors = useRefLens(config.initialErrors || ({} as Errors<Data>))
  const touchedFields = useRefLens({} as Touched<Data>)
  const validationStates = useRefLens({} as ValidationStates<Data>)
  const formValidationState = validationStates.compose(aggregateValidationState)

  const validator = useRef(config.validate)
  validator.current = config.validate

  // eslint-disable-next-line @grncdr/react-hooks/exhaustive-deps
  const validate = React.useCallback(
    serializeCalls(50, 250, async (opts?: ValidationOpts) => {
      if (!validator.current) {
        return {}
      }
      const nextErrors = await validator.current(values.get(), opts)
      const nextValidationState = zipTraverse<
        Data,
        string | ReactElement | undefined,
        boolean,
        ValidationState
      >(
        (error?: string | ReactElement | undefined, touched?: boolean): ValidationState => {
          return touched ? (error ? 'error' : 'validated') : 'none'
        },
        nextErrors,
        touchedFields.get(),
      )
      errors.set(nextErrors)
      validationStates.set(nextValidationState)
      rerender()
      return nextErrors
    }),
    [],
  )

  const submitting = React.useRef(false)
  const disabled = React.useRef(false)
  disabled.current = config.disabled === true
  const submit = async () => {
    if (submitting.current) {
      return
    }
    submitting.current = true
    disabled.current = true
    rerender()
    try {
      // this is racy, since I can't actually wait for the rerender to complete :\
      // it would be nice to use state/setState for submitting, but that breaks
      // memoization of fieldsets
      const currentValues = values.get()
      rootFieldset.touchAll()
      await validate()
      const currentErrors = errors.get()
      if (currentErrors && !flatIterator(currentErrors).next().done) {
        // validation failed
        return
      }
      await config.onSubmit(currentValues)
    } catch (error) {
      if (error instanceof FormSubmitError) {
        errors.set(error.validationErrors)
      } else {
        throw error
      }
    } finally {
      submitting.current = false
      disabled.current = false
      rerender()
    }
  }

  const rootFieldset = React.useMemo(
    () =>
      makeFieldset(
        '',
        values.observe(() => {
          rerender()
        }),
        errors,
        touchedFields,
        validationStates.observe(() => {
          rerender()
        }),
        disabled,
        validate,
      ),
    [values, errors, touchedFields, validationStates, rerender, validate],
  )

  return {
    ...rootFieldset,
    validate,
    submit,
    meta: {
      touched: !empty(touchedFields.get()),
      get validationState() {
        return formValidationState.get()
      },
      submitting: submitting.current,
      disabled: disabled.current,
    },
  }
}

export class FormSubmitError<T> extends Error {
  validationErrors: Errors<T>

  constructor(validationErrors: Errors<T>) {
    super('submitted data was rejected')
    this.validationErrors = validationErrors
  }
}

const aggregateValidationState = Lens.make<Errors<unknown>, ValidationState>(
  errors => worstValidationState2(errors),
  (_, errors) => errors,
)

function worstValidationState2(states: ValidationStates<unknown>): ValidationState {
  let worst: ValidationState = 'none'
  for (const [, state] of flatIterator(states)) {
    switch (state) {
      case 'error': {
        return 'error'
      }
      case 'validated': {
        worst = state
      }
    }
  }
  return worst
}

function makeFieldset<D extends object>(
  prefix: string,
  values: RefLens<D>,
  errors: RefLens<Errors<D>>,
  touchedFields: RefLens<Touched<D>>,
  validationStates: RefLens<ValidationStates<D>>,
  disabled: React.RefObject<boolean>,
  validate: () => void,
): Fieldset<D> {
  type ObjectFields = { [K in keyof D]: D[K] extends object ? D[K] : never }
  type ArrayFields = { [K in keyof D]: D[K] extends (infer _E)[] ? D[K] : never }

  const fields = memoMap(
    (key: keyof D & string): Field<D[typeof key]> => {
      const value = values.compose(Lens.index(key))
      const error = errors.compose(Lens.index(key))
      const validationState = validationStates.compose(
        Lens.map(
          Lens.index<ValidationStates<D>, keyof D>(key),
          maybeValidationState => {
            if (typeof maybeValidationState === 'string') {
              return maybeValidationState as ValidationState
            }
            return 'none' as ValidationState
          },
          validationState => validationState as ValidationStates<D>[typeof key],
        ),
      )
      const touched = touchedFields.compose<boolean>(
        Lens.map(
          Lens.index<Touched<D>, keyof D>(key),
          maybeBoolean => typeof maybeBoolean === 'boolean' && maybeBoolean,
          blah => blah as Touched<D>[typeof key],
        ) as Lens.Lens<Touched<D>, boolean>,
      )

      const name = `${prefix}${key}`

      return makeField(
        name,
        value,
        // sketchy casting, but ok
        (error as RefLens<unknown>) as RefLens<string | undefined>,
        touched,
        validationState,
        disabled,
        validate,
      )
    },
  )

  const fieldsets = memoMap(
    (key: keyof ObjectFields & string): Fieldset<ObjectFields[typeof key]> => {
      return makeFieldset(
        `${prefix}${key}.`,
        (values as RefLens<ObjectFields>).compose(Lens.index(key)),
        errors.compose(Lens.index(key)).withDefault({} as Errors<D>[typeof key]) as any,
        touchedFields.compose(Lens.index(key)).withDefault({} as Touched<D>[typeof key]) as any,
        validationStates
          .compose(Lens.index(key))
          .withDefault({} as ValidationStates<D>[typeof key]) as any,
        disabled,
        validate,
      )
    },
  )

  const fieldsetLists = memoMap(
    (key: keyof ArrayFields & string): FieldsetList<D[typeof key]> => {
      const fs = (fieldsets.get(key) as unknown) as Fieldset<unknown[]>
      return {
        clear: () => {
          fs.reset([] as any)
        },
        push: element => {
          fs.reset(fs.values().concat([element]))
        },
        remove: index => {
          const foo = fs.values().slice()
          foo.splice(index, 1)
          fs.reset(foo)
        },
        filter: fn => {
          fs.reset(fs.values().filter(fn as any))
        },
        map: fn => {
          const length = fs.field('length').get()
          const results = []
          for (let i = 0; i < length; i++) {
            results.push(fn(fs.fieldset(i) as any, i))
          }
          return results
        },
        values: fs.values as any,
        size: () => fs.values().length,
      }
    },
  )

  return {
    values() {
      return values.get()
    },
    errors() {
      return errors.get()
    },
    reset: (next, clearTouched = false) => {
      if (clearTouched) {
        touchedFields.set({})
      }
      if (Array.isArray(next)) {
        values.set((next as unknown) as D)
      } else if (typeof next === 'object') {
        values.set({ ...values.get(), ...next })
        typedKeys(next).forEach(k => typeof k === 'string' && fields.get(k).touch())
      } else {
        values.set(next)
      }
      validate()
    },
    touchAll: () => {
      for (const field of fields.values()) {
        field.touch()
      }
      for (const fs of fieldsets.values()) {
        fs.touchAll()
      }
    },
    field: fields.get as Fieldset<D>['field'],
    fieldset: fieldsets.get as Fieldset<D>['fieldset'],
    list: fieldsetLists.get as Fieldset<D>['list'],
  }
}

function makeField<T>(
  name: string,
  value: RefLens<T>,
  error: RefLens<string | undefined>,
  touched: RefLens<boolean>,
  validationState: RefLens<ValidationState>,
  disabled: React.RefObject<boolean>,
  validate: () => void,
): Field<T> {
  let inputProps: InputProps<T>

  const comp = <U>(lens: Lens.Lens<T, U>): Field<U> =>
    makeField(name, value.compose(lens), error, touched, validationState, disabled, validate)

  // this would require dependent (or partially applied) types, so use unknown instead
  const compositions = memoMap<Lens.Lens<T, unknown>, Field<unknown>>(comp)

  return {
    comp: <U>(lens: Lens.Lens<T, U>) => compositions.get(lens as Lens.Lens<T, unknown>) as Field<U>,
    compose: <U>(lens: Lens.Lens<T, U>) =>
      compositions.get(lens as Lens.Lens<T, unknown>).props as InputProps<U>,
    get: value.get,
    set: (next: T) => {
      value.set(next)
      validationState.set('none')
    },
    touch: () => touched.set(true),
    get props() {
      inputProps ??= {
        name,
        onChangeValue: (next: T) => {
          value.set(next)
          validationState.set('none')
          touched.set(true)
        },
        onBlur: validate,
        get value() {
          return value.get()
        },
        get disabled() {
          return disabled.current || false
        },
        get validationState() {
          return validationState.get()
        },
        get error() {
          return (error.get() as unknown) as string | undefined
        },
      }
      return inputProps
    },
  }
}
