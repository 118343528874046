/**
 * Iterate recursively over any object, yielding `[path, value]` pairs for each
 * non-null primitive value.
 *
 * @param data an object or array
 * @param prefix current path prefix
 */
export default function* flatIterator(
  data: {
    [key: string]: unknown
  },
  prefix = '',
): IterableIterator<[string, unknown]> {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const val = data[key]
      if (typeof val === 'object' && val != null) {
        yield* flatIterator(
          val as {
            [key: string]: unknown
          },
          prefix + key + '.',
        )
      } else {
        yield [prefix + key, val]
      }
    }
  }
}
