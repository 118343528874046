const $get = Symbol()
const $set = Symbol()

/** Lens is a combination of getter and setter, both of which are private */
export interface Lens<From, To> {
  readonly [$get]: (s: From) => To
  readonly [$set]: (a: To, s: From) => From
}

export function compose<S, A, X>(parent: Lens<S, A>, child: Lens<A, X>): Lens<S, X> {
  return {
    [$get]: s => {
      const a = parent[$get](s)
      return child[$get](a)
    },
    [$set]: (x, s) => {
      const a1 = parent[$get](s)
      const a2 = child[$set](x, a1)
      return parent[$set](a2, s)
    },
  }
}

export function index<S extends object, K extends keyof S>(k: K): Lens<S, S[K]> {
  return {
    [$get]: s => s[k],
    [$set]: (a, s) => {
      if (typeof s !== 'object' || s === null) {
        return s
      }
      const s2 = shallowCopy(s)
      s2[k] = a
      return s2
    },
  }
}

export function substring(start: number, end: number): Lens<string, string> {
  return make(
    string => string.substring(start, end),
    (part, target) => target.substring(0, start) + part + target.substring(end),
  )
}

function shallowCopy<T>(v: T): T {
  if (!v || typeof v !== 'object') {
    return v
  }
  if (Array.isArray(v)) {
    return (v.slice() as unknown) as T
  }
  return Object.assign({}, v)
}

export const id: Lens<unknown, unknown> = {
  [$get]: s => s,
  [$set]: s => s,
}

export function get<T, F>(lens: Lens<F, T>, target: F): T {
  return lens[$get](target)
}

export function set<T, F>(lens: Lens<F, T>, val: T, target: F): F {
  return lens[$set](val, target)
}

export function make<F, T>(get: (s: F) => T, set: (a: T, s: F) => F): Lens<F, T> {
  return { [$get]: get, [$set]: set }
}

export function map<F, T, U>(lens: Lens<F, T>, from: (t: T) => U, to: (u: U) => T): Lens<F, U> {
  return make(
    source => from(lens[$get](source)),
    (val, source) => lens[$set](to(val), source),
  )
}

export function withDefault<F, T>(fallback: T, lens: Lens<F, T>): Lens<F, T> {
  return make(
    source => {
      const upstream = lens[$get](source)
      if (typeof upstream === 'undefined') {
        return fallback
      } else {
        return upstream
      }
    },
    (val, source) =>
      typeof val === 'undefined' ? lens[$set](fallback, source) : lens[$set](val, source),
  )
}

const $nothing = Symbol('memo1$nothing')

export function memo1<F, T>(l: Lens<F, T>, eq: (a: T, b: T) => boolean = Object.is): Lens<F, T> {
  let current: T | typeof $nothing = $nothing
  return make(
    source => {
      current = get(l, source)
      return current
    },
    (val, target) => {
      if (current === $nothing || eq(current, val)) {
        return target
      }
      return set(l, val, target)
    },
  )
}
