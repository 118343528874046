import {
  BaseStyle,
  inputSpacing,
  inputValidationState,
  InputVariantProps,
} from '../forms/inputVariants'
import styled, { CSSObject } from '../styled'
import { flexItem, FlexItemProps } from '../styleFunctions/flex'
import { sizing, SizingProps } from '../styleFunctions/sizing'
import { spacing, SpacingProps } from '../styleFunctions/spacing'

export interface Props extends InputVariantProps, SizingProps, SpacingProps, FlexItemProps {
  labelText?: React.ReactNode
  styles?: CSSObject
  placeholder?: string
  autoFocus?: boolean
}

export const Input = styled.input<Props>(
  BaseStyle,
  inputSpacing,
  inputValidationState,
  sizing,
  spacing,
  flexItem,
)
